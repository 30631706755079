<template>
  <div class="offer">
    <div class="container-xl">
      <div class="card">
        <div v-if="!offer.uuid" class="text-center p-5"><i>Загрузка...</i></div>
        <div v-else-if="offer.uuid && offer.status === 'SOLD'" class="text-center p-5"><i>Предложение больше не действительно</i></div>
        <div v-else-if="offer.uuid" class="card-header">
          <div class="card-title">
            Уступка {{ offer.agreementNumber }}
            <img v-if="offer.guarantorPercent" src="@/assets/img/bay_back.png" alt="Buy Back">
          </div>
        </div>
        <div v-if="offer.uuid && offer.status !== 'SOLD'" class="card-body">
          <ul class="nav nav-tabs mb-3" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active"
                 id="details-tab"
                 data-toggle="tab"
                 href="#details"
                 role="tab"
                 aria-controls="details"
                 aria-selected="true"
              >
                Детали сделки
              </a>
            </li>
            <li v-if="offer.guarantorPercent" class="nav-item" role="presentation">
              <a class="nav-link"
                 id="seller-tab"
                 data-toggle="tab"
                 href="#seller"
                 role="tab"
                 aria-controls="seller"
                 aria-selected="true"
              >
                Информация о продавце
              </a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link"
                 id="project-tab"
                 data-toggle="tab"
                 href="#project"
                 role="tab"
                 aria-controls="project"
                 aria-selected="true"
              >
                Карточка проекта
              </a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show active" id="details" role="tabpanel" aria-labelledby="details-tab">
              <div class="row">
                <div class="col-12 col-md-6 col-lg-5 mt-3 mt-md-0">
                  <dl>
                    <dt>Тип сделки</dt>
                    <dd v-if="offer.guarantorPercent">Уступка с гарантией Buy&nbsp;Back</dd>
                    <dd v-else>Уступка требования без гарантии откупа</dd>
                    <dt>Заёмщик</dt>
                    <dd>{{ offer.borrowerName }}</dd>
                    <dt>Изначальный срок займа</dt>
                    <dd>{{ offer.project.initialTerm }} мес</dd>
                    <dt>Оставшейся срок займа</dt>
                    <dd>{{ offer.leftMonths }} мес</dd>
                    <dt>Дата размещения оферты</dt>
                    <dd>
                      <date-format :date="offer.createdAt"/>
                    </dd>
                    <dt>Срок действия оферты</dt>
                    <dd>
                      <date-format v-if="offer.expiredAt" :date="offer.expiredAt"/>
                      <span v-else>-</span>
                    </dd>
                    <dt>Размер займа</dt>
                    <dd>
                      <money :money="offer.project.investedSum"/>
                    </dd>
                    <dt>Размер предложения</dt>
                    <dd>
                      <money :money="offer.investmentMoney"/>
                    </dd>
                    <dt>Ставка займа</dt>
                    <dd>{{ Math.floor(offer.project.interestRate * 10) / 10 }}%</dd>
                    <dt v-if="offer.guarantorPercent">Продаваемая ставка</dt>
                    <dd v-if="offer.guarantorPercent">
                      {{ Math.floor((offer.project.interestRate - offer.guarantorPercent) * 10) / 10 }}%
                    </dd>
                    <dt>Комиссия за управление залогом</dt>
                    <dd>{{
                        Math.floor(((offer.project.interestRate - (offer.guarantorPercent ?? 0)) * projectDividendFee / 100) * 10) / 10
                      }}%
                    </dd>
                    <dt>Итоговая ставка для инвестора</dt>
                    <dd>
                      {{ Math.floor((finalPercent) * 10) / 10 }}%
                    </dd>
                    <dt>Стоимость залога</dt>
                    <dd>
                      <money v-if="offer.project.collateralSum" :money="offer.project.collateralSum"/>
                      <span v-else>-</span>
                    </dd>
                    <dt>LTV</dt>
                    <dd>{{ offer.ltv }}%</dd>
                  </dl>
                </div>
                <div v-if="offer.splittable && !isOfferAmountLessThanMinimum"
                     class="col-12 col-md-6 col-lg-3 mt-3 mt-md-0">
                  <div class="h6 mb-3">Распределение инвестиций</div>
                  <vue3-chart-js
                      :id="chart.id"
                      ref="chart"
                      :type="chart.type"
                      :data="chart.data"
                      :options="chart.options"
                  ></vue3-chart-js>
                    <div v-if="hasOverduePayments" class="border-warning border rounded p-2">
                        Этот проект имеет просроченные платежи!
                    </div>
                </div>
                <div v-if="offer.splittable && !isOfferAmountLessThanMinimum"
                     class="col-12 col-md-6 col-lg-4 order-first order-md-last">
                  <div class="card confirmation-form">
                    <div class="card-body">
                      <dl>
                        <dt>Доступно</dt>
                        <dd>
                          <money :money="investmentAvailableSum"/>
                        </dd>
                      </dl>
                      <div v-if="lenderVerified && investmentAvailable" class="form-group">
                        <div class="d-inline-flex w-100">
                          <input-money v-model="partialInvestmentAmount"
                                       :min="minInvestment"
                                       :max="investmentAvailableAmount"
                                       :currency="true"
                                       @blur="handleInput"
                                       class="form-control"
                                       autocomplete="off"
                                       required/>
                          <div class="my-auto ml-1">
                            <c-tooltip text="Сумма приобретаемого права требования к основному долгу"/>
                          </div>
                        </div>
                        <input-errors :errors="errors.partialInvestmentAmount"/>
                      </div>
                      <div v-if="lenderVerified === false" class="card border-info">
                        <div class="card-body">
                          Для возможности инвестировать необходимо
                          <router-link :to="{ name: 'authorization' }" class="font-weight-bold">пройти авторизацию
                          </router-link>
                        </div>
                      </div>
                      <div v-if="lenderVerified && investmentAllow === false" class="card border-info">
                        <div class="card-body">
                          В Вашем аккаунте отключена возможность инвестирования.<br>
                          Для более детальной информации обратитесь в службу технической поддержки:
                          <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
                        </div>
                      </div>
                      <div v-else-if="lenderVerified && (investmentAvailable === false || balanceEnough === false)"
                           class="card border-info">
                      <div v-if="limitReached" class="card-body">
                          Вы достигли лимита для инвестирования в год.<br>
                          Чтобы увеличить этот лимит обратитесь в службу технической поддержки:
                          <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
                      </div>
                        <div v-else-if="balanceEnough === false" class="card-body">
                          Недостаточно средств на балансе для совершения инвестиции.<br>
                          Минимальная сумма:
                          <money :money="minInvestmentSum"/><br/>
                          С учетом наценки продавца:
                          <money :money="minInvestmentSumWithDiscount"/>
                          <br>
                        </div>
                        <div v-else-if="!offer.guarantorPercent && (investmentAvailableAmount < minInvestment)" class="card-body">
                          Вы достигли лимита для инвестирования в год.<br>
                          Чтобы увеличить этот лимит обратитесь в службу технической поддержки:
                          <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
                        </div>
                        <div v-else class="card-body">
                          Недостаточный лимит для покупки, осталось:
                          <money :money="investmentAvailableSum"/>
                          <br>
                          Чтобы увеличить этот лимит обратитесь в службу технической поддержки:
                          <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
                        </div>
                      </div>
                      <div v-if="lenderVerified && investmentAllow && investmentAvailable && balanceEnough"
                           class="text-center">
                        <div class="row justify-content-center text-muted">
                          <div v-if="offer.price.amount > offer.investmentMoney.amount">
                            Цена с учетом наценки
                            <money :money="priceWithDiscount"/>
                          </div>
                          <div v-else-if="offer.price.amount < offer.investmentMoney.amount">
                            Цена с учетом скидки
                            <money :money="priceWithDiscount"/>
                          </div>
                        </div>
                        <button @click="createBuyBackConfirmation" type="button" class="btn btn-success w-100">
                          Инвестировать
                        </button>
                      </div>
                      <div v-if="lenderVerified && investmentAllow && investmentAvailable && balanceEnough"
                           class="form-group mt-3">
                        <input v-model="partialInvestmentAmount"
                               :min="minInvestment"
                               :max="investmentAvailableAmount"
                               ref="investmentInputSlider"
                               @keyup="validatePartialInvestmentAmount"
                               @blur="validatePartialInvestmentAmount"
                               type="range"
                               class="form-control">
                        <div class="row">
                          <div class="col-5">
                            <money :money="minInvestmentSum"/>
                          </div>
                          <div class="col-2 text-center">из</div>
                          <div class="col-5 text-right">
                            <money :money="investmentAvailableSum"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="col-12 col-md-6 col-lg-4 offset-lg-3 order-first order-md-last">
                  <div class="card confirmation-form">
                    <div class="card-body">
                      <dl>
                        <dt>Размер предложения</dt>
                        <dd>
                          <money :money="offer.investmentMoney"/>
                        </dd>
                        <dt>Цена</dt>
                        <dd>
                          <money :money="offer.price"/>
                        </dd>
                      </dl>
                      <div v-if="lenderVerified === false" class="card border-info">
                        <div class="card-body">
                          Для возможности инвестировать необходимо
                          <router-link :to="{ name: 'authorization' }" class="font-weight-bold">пройти авторизацию
                          </router-link>
                        </div>
                      </div>
                      <div v-if="lenderVerified && investmentAllow === false" class="card border-info">
                        <div class="card-body">
                          В Вашем аккаунте отключена возможность инвестирования.<br>
                          Для более детальной информации обратитесь в службу технической поддержки:
                          <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
                        </div>
                      </div>
                      <div v-else-if="lenderVerified && (investmentAvailable === false || balanceEnough === false)"
                           class="card border-info">
                        <div v-if="limitReached" class="card-body">
                            Вы достигли лимита для инвестирования в год.<br>
                            Чтобы увеличить этот лимит обратитесь в службу технической поддержки:
                            <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
                        </div>
                        <div v-else-if="balanceEnough === false" class="card-body">
                          Недостаточно средств на балансе для совершения инвестиции.<br>
                        </div>
                        <div v-else-if="!offer.guarantorPercent && (investmentAvailableAmount < minInvestment) && !this.isOfferAmountLessThanMinimum" class="card-body">
                          Вы достигли лимита для инвестирования в год.<br>
                          Чтобы увеличить этот лимит обратитесь в службу технической поддержки:
                          <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
                        </div>
                        <div v-else class="card-body">
                          Недостаточный лимит для покупки, осталось:
                          <money :money="investmentAvailableSum"/>
                          <br>
                          Чтобы увеличить этот лимит обратитесь в службу технической поддержки:
                          <a href="mailto:support@lendly.ru" class="font-weight-bold">support@lendly.ru</a>
                        </div>
                      </div>
                      <div v-if="lenderVerified && investmentAllow && balanceEnough" class="text-center">
                        <button @click="createConfirmation()" type="button" class="btn btn-success w-100">
                          Инвестировать
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="offer.sellerInfo" class="tab-pane fade" id="seller" role="tabpanel" aria-labelledby="seller-tab">
              <div class="text-muted">Данные о продавце инвестиции по состоянию на
                <date-format :date="now"/>
              </div>
              <div class="row my-4">
                <div class="col-sm my-auto">
                  <div class="h3">{{ offer.sellerInfo.name }}</div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="text-muted">ИНН {{ offer.sellerInfo.inn }}</div>
                  <div v-if="offer.sellerInfo.siteUrl" class="mt-2">
                    Сайт: <a :href="offer.sellerInfo.siteUrl" target="_blank">{{ offer.sellerInfo.siteUrl }}</a>
                  </div>
                </div>
                <div class="col-12 col-md-6 text-md-right">
                  <date-format :date="offer.sellerInfo.startInvestments"/>
                  <div class="text-muted mt-1">Дата начала инвестирования на платформе</div>
                </div>
              </div>
              <hr>
              <dl>
                <dt class="text-black">Всего выдано займов на платформе:</dt>
                <dd class="text-size-2">
                  <invested-info :info="offer.sellerInfo.totalInvested" :counter="false"/>
                </dd>
              </dl>
              <hr>
              <dl>
                <dt class="text-black">Текущий портфель займов на платформе:</dt>
                <dd class="text-size-2">
                  <invested-info :info="offer.sellerInfo.activeInvested"/>
                </dd>
                <dt>С просрочкой более 30 дней</dt>
                <dd class="text-size-1">
                  <invested-info :info="offer.sellerInfo.activeInvestedOverdue30"/>
                </dd>
                <dt>С просрочкой более 60 дней</dt>
                <dd class="text-size-1">
                  <invested-info :info="offer.sellerInfo.activeInvestedOverdue60"/>
                </dd>
              </dl>
              <div v-if="offer.sellerInfo.sellerBalance">
                <dl>
                  <dt class="text-black my-auto">Текущий остаток на номинальном счете</dt>
                  <dd class="text-size-2 mt-1">
                    <money :money="offer.sellerInfo.sellerBalance"/>
                  </dd>
                </dl>
              </div>
              <hr>
              <dl>
                <dt class="text-black">Текущий объем займов, проданных с гарантией откупа:</dt>
                <dd class="text-size-2">
                  <div v-if="offer.sellerInfo.activeGuaranteedInvested">
                    <invested-info :info="offer.sellerInfo.activeGuaranteedInvested"/>
                  </div>
                </dd>
                <dt>С просрочкой более 10 дней</dt>
                <dd class="text-size-1">
                  <div v-if="offer.sellerInfo.activeGuaranteedInvestedOverdue10">
                    <invested-info :info="offer.sellerInfo.activeGuaranteedInvestedOverdue10"/>
                  </div>
                </dd>
                <dt>С просрочкой более 30 дней</dt>
                <dd class="text-size-1">
                  <div v-if="offer.sellerInfo.activeGuaranteedInvestedOverdue30">
                    <invested-info :info="offer.sellerInfo.activeGuaranteedInvestedOverdue30"/>
                  </div>
                </dd>
                <dt>С просрочкой более 60 дней</dt>
                <dd class="text-size-1 d-flex">
                  <div v-if="offer.sellerInfo.activeGuaranteedInvestedOverdue60">
                    <invested-info :info="offer.sellerInfo.activeGuaranteedInvestedOverdue60"/>
                  </div>
                  <span
                      v-if="offer.sellerInfo.activeGuaranteedInvestedOverdue60 && offer.sellerInfo.activeGuaranteedInvestedOverdue60.count > 0"
                      class="text-muted text-size-0 ml-5"
                  >
                    ожидается откуп
                  </span>
                </dd>
              </dl>
              <hr>
              <div v-if="offer.sellerInfo.sellerBalance">
                <dl>
                  <dt class="text-black">Доступный лимит продажи с гарантией откупа</dt>
                  <dd class="text-size-2">
                    <money :money="buybackAvailable"/>
                  </dd>
                </dl>
              </div>
              <div v-html="offer.sellerInfo.info"></div>
            </div>
            <div class="tab-pane fade" id="project" role="tabpanel" aria-labelledby="project-tab">
              <detailed-project :uuid="offer.project.uuid" :card="false"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <processing :show="processing"/>
  </div>
</template>

<script>
import {
  DateFormat,
  DetailedProject,
  formatAmount,
  InputErrors,
  InputMoney,
  InvestedInfo,
  Money,
  Processing,
  CTooltip
} from "@/components/common";
import {appConfig} from "@/configs";
import {Money as ApiMoney, TransactionType} from "@/utils/api-client";
import {createFeeSettingsProvider, createOfferProvider, createOfferPurchaseManager} from "@/utils/di";
import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'
import moment from "moment/moment";

export default {
  name: "Offer",
  components: {
    DetailedProject,
    DateFormat,
    InputErrors,
    InputMoney,
    InvestedInfo,
    Money,
    Processing,
    Vue3ChartJs,
    CTooltip
  },
  inject: ["profile"],
  data() {
    return {
      offer: {},
      partialInvestmentAmount: null,
      errors: {},
      processing: false,
      feeSettings: null
    };
  },
  async mounted() {
    const provider = await createOfferProvider();
    this.offer = await provider.getOne(this.uuid);
    this.partialInvestmentAmount = this.investmentAvailableAmount;

    const feeSettingsProvider = await createFeeSettingsProvider();
    this.feeSettings = await feeSettingsProvider.getListForProject(await this.offer.project.uuid);
    this.validatePartialInvestmentAmount();
  },
  computed: {
    hasOverduePayments(){
        if (this.offer?.project?.paymentSchedule?.payments) {
            for (const payment of this.offer.project.paymentSchedule.payments) {
                const paymentDate = moment(moment(payment.paymentDate).format('Y-MM-DD'));
                let paidAt;
                if (!payment.paidAt) {
                    paidAt = moment(moment().format('Y-MM-DD'))
                } else {
                    paidAt = moment(moment(payment.paidAt).format('Y-MM-DD'));
                }
                if (paidAt.isAfter(paymentDate)) {
                    return true;
                }
            }
        }
        return false;
    },
    now() {
      return new Date().toISOString()
    },
    uuid() {
      return this.$route.params.uuid;
    },
    lenderVerified() {
      return this.profile.lenderVerified;
    },
    partialInvestmentMoney() {
      return this.partialInvestmentAmount ? new ApiMoney(Math.floor(this.partialInvestmentAmount * 100), "RUB") : null;
    },
    investmentAvailable() {
      if (this.isOfferAmountLessThanMinimum) {
        return this.investmentAvailableSum &&
            this.investmentAvailableSum.amount >= this.offer.investmentMoney.amount
      }
      if (this.offer.splittable || this.offer.guarantorPercent) {
        return this.investmentAvailableSum &&
            this.investmentAvailableSum.amount >= this.minInvestmentSum.amount;
      }
      return this.investmentAvailableSum &&
          this.investmentAvailableSum.amount >= this.offer.investmentMoney.amount &&
          this.investmentAvailableSum.amount >= this.minInvestmentSum.amount;
    },
    investmentAllow() {
      return this.profile.investmentAllow;
    },
    balanceEnough() {
      if ((this.offer.guarantorPercent || this.offer.splittable) && !this.isOfferAmountLessThanMinimum) {
        return (this.profile.balance.amount >= this.minInvestmentSum.amount) && (this.investmentAvailableSum.amount >= this.minInvestmentSum.amount);
      }
      return this.profile.balance.amount >= this.offer.price.amount;
    },
    investmentAvailableSum() {
      if (this.profile.balance && this.offer.investmentMoney) {
        let amount = this.offer.investmentMoney.amount;
        if (!this.offer.guarantorPercent && (this.profile.investmentLimit && amount > this.profile.investmentLimit.amount)) {
            amount = this.profile.investmentLimit.amount;
        }
        if (amount > this.profile.balance.amount) {
          amount = this.profile.balance.amount;
          if (this.offer.splittable) {
            return new ApiMoney(Math.floor(amount / (1 + this.discountPercent)), this.offer.investmentMoney.currencyCode);
          }
        }

        return new ApiMoney(amount, this.offer.investmentMoney.currencyCode);
      }
    },
    finalPercent() {
      const guarantorPercent = this.offer.guarantorPercent ?? 0;
      return Math.floor((this.offer.project.interestRate - guarantorPercent) * 10) / 10 -
          Math.floor(((this.offer.project.interestRate - guarantorPercent) * this.projectDividendFee / 100) * 10) / 10;
    },
    investmentAvailableAmount() {
      return this.investmentAvailableSum && Math.floor(this.investmentAvailableSum.amount / 100)
    },
    minInvestment() {
      if (this.offer.project.minOffer) {
          return this.offer.project.minOffer.amount / 100;
      }
      return appConfig.minInvestmentSecondary;
    },
    minInvestmentSum() {
      return new ApiMoney(this.minInvestment * 100, "RUB");
    },
    minInvestmentSumWithDiscount() {
        return new ApiMoney(this.minInvestment * (1+this.discountPercent) * 100, "RUB");
    },
    isOfferAmountLessThanMinimum() {
      return (this.offer.investmentMoney.amount < this.minInvestmentSum.amount);
    },
    discountPercent() {
      const price = this.offer.price.amount;
      const sum = this.offer.investmentMoney.amount;
      return (price - sum) / sum;
    },
    priceWithDiscount() {
      return new ApiMoney(this.partialInvestmentMoney.amount * (1 + this.discountPercent), "RUB");
    },
    chart() {
      const othersAmount = Math.floor((this.offer.project.investedSum.amount - this.offer.investmentMoney.amount) / 100);
      const otherPercent = Math.floor((this.offer.project.investedSum.amount - this.offer.investmentMoney.amount) / this.offer.project.investedSum.amount * 100);
      const buyerAmount = Math.floor(this.investmentAvailableSum.amount / 100);
      const buyerPercent = Math.floor(this.investmentAvailableSum.amount / this.offer.project.investedSum.amount * 100);
      const sellerAmount = Math.floor(this.offer.project.investedSum.amount / 100) - othersAmount - buyerAmount;
      const sellerPercent = 100 - otherPercent - buyerPercent;
      return {
        type: "doughnut",
        data: {
          labels: [
            `Доля Покупателя - ${buyerPercent}%`,
            `Доля Продавца - ${sellerPercent}%`,
            `Доли других инвесторов - ${otherPercent}%`
          ],
          datasets: [{
            backgroundColor: ["#21e58a", "#1140fe", "#ececec"],
            data: [buyerAmount, sellerAmount, othersAmount]
          }]
        },
        options: {
          animation: false,
          responsive: true,
          aspectRatio: 1,
          elements: {
            arc: {
              borderWidth: 0
            }
          },
          legend: {
            position: "bottom",
            align: "start",
            labels: {
              fontSize: 14,
              padding: 15,
              usePointStyle: true
            }
          }
        }
      };
    },
      limitReached(){
        if (!this.profile.investmentLimit) return false;
        return this.profile.investmentLimit.amount === 0;
      },
    buybackAvailable() {
      if (this.offer.sellerInfo && this.offer.sellerInfo.sellerBalance) {
        const sellerBalance = this.offer.sellerInfo.sellerBalance.amount;
        const sellerDeposit = this.offer.sellerInfo.activeInvested.sum.amount;
        let sellerBuyback = 0;
        if (this.offer.sellerInfo.activeGuaranteedInvested.sum.amount) {
          sellerBuyback = this.offer.sellerInfo.activeGuaranteedInvested.sum.amount;
        }
        return new ApiMoney((sellerBalance + sellerDeposit) * 2 - sellerBuyback, "RUB")
      }
    },
    projectDividendFee() {
      let fee;
      if (this.feeSettings) {
        this.feeSettings.items.map(item => {
          if (item.transactionType === TransactionType.DIVIDEND_INTEREST_FEE) {
            fee = item.percent;
          }
        })
      }
      fee = fee ?? 0;
      return fee;
    }
  },
  methods: {

    updateChart() {
      if (this.validatePartialInvestmentAmount()) {
        if (this.offer.project) {
          let othersAmount = Math.floor((this.offer.project.investedSum.amount - this.offer.investmentMoney.amount) / 100);
          let otherPercent = Math.floor((this.offer.project.investedSum.amount - this.offer.investmentMoney.amount) / this.offer.project.investedSum.amount * 100);
          let sellerAmount = 0;
          let sellerPercent = 0;
          let buyerAmount = 0;
          let buyerPercent = 0;
          if (this.partialInvestmentMoney.amount) {
            buyerAmount = Math.floor(this.partialInvestmentMoney.amount / 100);
            buyerPercent = Math.floor(this.partialInvestmentMoney.amount / this.offer.project.investedSum.amount * 100);
            sellerAmount = Math.floor(this.offer.project.investedSum.amount / 100) - othersAmount - buyerAmount;
            sellerPercent = 100 - otherPercent - buyerPercent;
          }
          this.chart.data.labels = [
            `Доля Покупателя - ${buyerPercent}%`,
            `Доля Продавца - ${sellerPercent}%`,
            `Доли других инвесторов - ${otherPercent}%`
          ];
          this.chart.data.datasets = [{
            backgroundColor: ["#21e58a", "#1140fe", "#ececec"],
            data: [buyerAmount, sellerAmount, othersAmount]
          }];
        }
        this.$refs.chart.update();
      }
    },
    handleInput() {
      if (this.partialInvestmentAmount >= this.minInvestment && this.partialInvestmentAmount <= this.investmentAvailableAmount) {
        let step = 1000;
        if (this.minInvestment > 10000) {
          step = 10000;
        }
        this.partialInvestmentAmount = Math.round(this.partialInvestmentAmount / step) * step;
        // Явно устанавливаем значение для элемента формы
        const inputElement = document.querySelector('.form-control');
        if (inputElement) {
          inputElement.value = this.partialInvestmentAmount;
        }
      }
    },

    validatePartialInvestmentAmount() {
      if (this.partialInvestmentAmount === 0) {
        this.partialInvestmentAmount = 1;
      }
      if (this.partialInvestmentAmount >= this.minInvestment && this.partialInvestmentAmount <= this.investmentAvailableAmount) {
        let step = 1000;
        if (this.minInvestment > 10000) {
            step = 10000;
        }

        this.$refs.investmentInputSlider.step = step;
      } else {
        this.$refs.investmentInputSlider.step = 1;
      }
      delete this.errors.partialInvestmentAmount;
      if (this.partialInvestmentAmount < this.minInvestment) {
        this.errors.partialInvestmentAmount = [`Минимальная сумма инвестиции ${formatAmount(this.minInvestment)} руб.`];
      } else if (this.partialInvestmentAmount > this.investmentAvailableAmount) {
        this.errors.partialInvestmentAmount = [`Максимальная сумма инвестиции ${formatAmount(this.investmentAvailableAmount)} руб.`];
      } else {
        return true;
      }
      return false;
    },
    createBuyBackConfirmation() {
      if (this.validatePartialInvestmentAmount()) {
        this.createConfirmation(this.partialInvestmentMoney)
      }
    },
    async createConfirmation(partialInvestmentMoney) {
      this.processing = true;
      try {
        const manager = await createOfferPurchaseManager();
        const result = await manager.createConfirmation(this.offer.uuid, partialInvestmentMoney);
        const routeName = this.offer.guarantorPercent ? "purchaseByBackConfirmation" : "purchaseConfirmation";
        await this.$router.push({name: routeName, params: {uuid: this.offer.uuid, confirmationUuid: result.uuid}});
      } finally {
        this.processing = false;
      }
    }
  },
  watch:
      {
        partialInvestmentMoney() {
          if (this.$refs.chart) this.updateChart();
        }
      }
}
</script>

<style lang="scss">
.offer {
  input[type="range"] {
    padding: 0;
    background: #21e58a;
  }

  .text-black {
    color: #000;
  }

  .text-size-0 {
    font-size: 1rem;
  }

  .text-size-1 {
    font-size: 1.125rem;
  }

  .text-size-2 {
    font-size: 1.5rem;
  }

  .card-title {
    img {
      height: 2rem;
      vertical-align: top;
      margin-left: 1rem;
    }
  }

  .nav-tabs {
    .nav-link,
    .nav-link:hover,
    .nav-link:focus,
    .nav-link.active {
      position: relative;
      border: none;
      background: none;
    }

    .nav-link {
      color: #000;
      margin-left: 0;
      margin-right: 0;

      @media (min-width: 768px) { // Bootstrap MD
        margin-left: 0.25rem;
        margin-right: 0.25rem;
      }

      @media (min-width: 1040px) {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }

      @media (min-width: 1140px) {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    .nav-link.active {
      color: #21e58a;
    }

    .nav-link.active:before {
      content: "";
      background: #21e58a;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.2rem;
      width: 100%;
    }
  }

  .confirmation-form {
    border: 0.125rem solid #21e58a;

    dl {
      dt, dd {
        margin-bottom: 1rem;
      }

      dd {
        font-size: 1.25rem;
      }
    }
  }
}
</style>
